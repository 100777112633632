import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { HeroText, HeroHeader, HeroDescription } from "../styledComponents/heroBanner"
import { HeroFullScreenImageWrapper } from "../styledComponents/heroFullscreenImage"
import { Article, ArticleH1, ArticleHeader, ArticleSection } from "../styledComponents/article"

import { StyledLinkButton } from "../styledComponents/button"
import { Section } from "../styledComponents/section"
import CardList from "../components/CardList"
import AllServicesList from "../components/servicesList"


const Home = ({ data, location, createCookie }) => {
  const blogPosts = data.allWpPost.nodes;
  return (
    <Layout location={location} isHomePage hasFullScreenHero>
      <Seo
        title="The Internet Consultancy Group"
        description=""
        image=""
        url=""
      />

      <HeroFullScreenImageWrapper backgroundColor="var(--dark-blue-mid)" gridRowGap="0">
        <StaticImage
          loading="eager"
          alt=""
          backgroundcolor="#002F4A"
          layout="fullWidth"
          src={
            "../images/pexels-hasan-albari-1229861.jpg"
          }
          formats={["auto", "webp", "avif"]}
        />
        <HeroText fontColor="var(--white)" isFullScreenImage>
          <HeroHeader level={1} fontColor="var(--white)">
            We help businesses improve their online visibility.
          </HeroHeader>
          <HeroDescription>Looking for an experienced web design agency? Our team has over 20 years of expertise in design, user experience, software development and SEO to help your business succeed online.</HeroDescription>
        </HeroText>
      </HeroFullScreenImageWrapper>
      <Article
        itemScope
        itemType="http://schema.org/Article"
        servicesList
      >
        <ArticleHeader>
          <ArticleH1 align="center" itemProp="headline">Our services we have to offer</ArticleH1>
          <p>We are passsionate about web technology and making websites as user friendly and optimised for the best experience possible.</p>
        </ArticleHeader>
        <ArticleSection isServicesList>
          <AllServicesList isHomePage />
        </ArticleSection>
      </Article>

      <Section background="var(--m  id-blue-mid)" type="pullout-with-image">
        <h2>People on mobile devices account for about half of the web traffic worldwide. In the second quarter of 2022, mobile devices (excluding tablets) generated 58.99% of global website traffic. </h2>
        <StaticImage
          loading="lazy"
          alt=""
          backgroundcolor="#002F4A"
          src={
            "../images/mobile-users-stats.svg"  
          }
          formats={["auto", "webp", "avif"]}
        />
      </Section>
      <Section type="cardlist">
        <h2>Articles about SEO, UX/UI, Design &amp; Technology</h2>
        <CardList posts={blogPosts} />
        <StyledLinkButton className="archive-link" to="/blog/">Read more of our technology articles</StyledLinkButton>
      </Section>

    </Layout>
  )
}

export default Home

export const pageQuery = graphql`
  query WordpressPosts {
    allWpPost(limit: 3, sort: {fields: date, order: DESC}) {
      nodes {
          title
          excerpt
          uri
          date(formatString: "MMMM DD, YYYY")
          isSticky
          categories {
            nodes {
              slug
            }
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 630
                    quality: 100
                    placeholder: DOMINANT_COLOR
                    formats: [AUTO, WEBP, AVIF]
                    breakpoints: [320, 720, 1024, 1600]
                  )
                }
              }
            }
          }
        }
      }
}
`